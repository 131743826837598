import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from '../components/templates/BaseLayout';
import LoadingSpinner from '../components/atoms/animations/LoadingSpinner';
import Headline from '../components/atoms/typography/Headline';
import Paragraph from '../components/atoms/typography/Paragraph';
import Spacer from '../components/atoms/utility/Spacer';
import DownloadAppCTA from '../components/atoms/callouts/DownloadAppCTA';
import SEO from '../components/atoms/utility/seo';
import {
  APPLE_STORE_LINK,
  GOOGLE_PLAYSTORE_LINK
} from '../utils/helpers/download-link-urls';

const AppStoreRedirectPage = ({ data: { phoneDemoImg } }) => {
  const [hasRedirected, setRedirected] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (
      userAgent.indexOf('iPhone') !== -1 ||
      userAgent.indexOf('iPad') !== -1 ||
      userAgent.indexOf('iPod') !== -1
    ) {
      window.location.replace(APPLE_STORE_LINK);
    } else if (userAgent.indexOf('Android') !== -1) {
      window.location.replace(GOOGLE_PLAYSTORE_LINK);
    } else {
      console.log('Cannot detect mobile device');
      window.location.replace('/');
    }
    setTimeout(() => setRedirected(true), [2000]);
  }, []);
  return (
    <Layout>
      <SEO
        title='Download and Experience AI Agent Collaboration'
        description='Download the flockx AI Agent App to manage your AI Agent, kickoff collaborations with others, and discover new insights and actions your Agent can tap into.'
      />
      <h1 className='hidden'>
        Download flockx - The Live Activity Reporting App for a New Way
        to Experience Your City
      </h1>
      {!hasRedirected ? (
        <div className='flex flex-col items-center justify-between h-full w-full min-h-[80vh] py-10'>
          <div className='flex text-2xl lg:text-3xl'>
            Redirecting...{' '}
            <span className='ml-4'>
                            <LoadingSpinner />
                        </span>
          </div>
          <div></div>
        </div>
      ) : (
        <div className='grid grid-cols-1 lg:grid-cols-12 gap-4 items-center justify-center py-10 lg:py-20'>
          <div className='col-span-6 order-2 lg:order-1'>
            <div className='xl:ml-24 w-full flex lg:block items-center justify-center '>
              <GatsbyImage
                image={getImage(phoneDemoImg)}
                alt='A model of the new flockx mobile application.'
              />
            </div>
          </div>
          <div className='col-span-6 mx-auto lg:pl-20 order-1 lg:order-2'>
            <Headline
              headerText=''
              className='text-center lg:text-left mb-5 lg:mb-10 lg:text-[64px]'
              spanColor='#D965FE'
              postSpanText='Eliminate FOMO'
            />
            <Paragraph
              paragraphText='With Flockx, you can easily interact with the local music scene by seeing live activity reporting instantly. No more wondering what shows are happening tonight or where your favorite bands are playing.'
              className='text-center lg:text-left max-w-3xl mx-auto'
            />
            <DownloadAppCTA
              containerStyles='mx-auto w-full flex items-center lg:justify-start justify-center mt-8 lg:my-8'
              id='download-page'
            />
            <Spacer className='lg:hidden' />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default AppStoreRedirectPage;

export const query = graphql`
    query AppRedirectPageQuery {
        phoneDemoImg: file(
            name: { eq: "flockx-app-activity-reporting-screen" }
        ) {
            childImageSharp {
                gatsbyImageData(quality: 100, width: 445)
            }
        }
    }
`;
